import { render, staticRenderFns } from "./soga_page.vue?vue&type=template&id=4206f5f3&scoped=true&"
import script from "./soga_page.vue?vue&type=script&lang=js&"
export * from "./soga_page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4206f5f3",
  null
  
)

export default component.exports