<template>
  <span>
    <span v-if="curr<=1" style="color: lightgray;">{{prevText}}</span>
    <a v-else @click="onPageChange(-1)">{{prevText}}</a>
    &nbsp;{{curr}}/{{maxPage}}&nbsp;
    <span v-if="curr>=maxPage" style="color: lightgray;">{{nextText}}</span>
    <a v-else @click="onPageChange(1)">{{nextText}}</a>
  </span>
</template>

<script>
  export default {
    name: 'SogaPage',
    props: {
      current: {
        type: Number,
        default: 1
      },
      total: {
        type: Number,
        default: 0
      },
      pageSize: {
        type: Number,
        default: 1
      },
      prevText: {
        type: String,
        default: ' < '
      },
      nextText: {
        type: String,
        default: ' > '
      }
    },
    data () {
      return {
        curr: 1
      }
    },
    computed: {
      maxPage () {
        return Math.ceil(this.total / this.pageSize);
      }
    },
    methods: {
      onPageChange (step) {
        const val = this.curr + step;
        if (val >= 0 && val <= this.total) {
          this.curr = val;
          this.$emit('on-change', val);
        }
      }
    },
    watch: {
      current (val) {
        this.curr = val;
      }
    }
  }
</script>

<style scoped>
</style>
